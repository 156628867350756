<template>
    <div class="timeShift" :style="{ 'background-color': isShowIcon ? item.schemeC : '#61a5ff', color: item.status == '2' ? 'black' : '#fff' }">
        <div class="icon" v-show="isShowIcon">
            <i class="el-icon-close" v-if="item.eState == '3'"></i>
            <i class="el-icon-circle-check" v-if="item.eState == '2'"></i>
            <i class="el-icon-remove-outline" v-if="item.eState == '1'"></i>
        </div>
        <div :class="item.remark ? 'sign' : ''">{{ item.shiteNa }}</div>
        <div>{{ item.dTime }}</div>
        <div v-show="item.vehicleNo">{{ item.vehicleNo }}</div>
        <div v-show="item.driverNa">{{ item.driverNa }}</div>
    </div>
</template>
<script>
    export default {
        props: {
            item: {
                type: Object,
                default: () => {
                    return {};
                }
            },
            isShowIcon: {
                type: Boolean,
                default: true
            }
        },
        watch: {},
        data() {
            return {};
        }
    };
</script>
<style lang="scss" scoped="timeShift">
    .timeShift {
        position: relative;
        padding: 2px 10px;
        color: #fff;
        display: inline-block;
        width: 85px;
        // background-color: rgb(151, 135, 222);
        border-radius: 10px;
        box-shadow: 2px 2px 5px #808080;
        .icon {
            position: absolute;
            right: 5px;
            font-size: 13px;
            color: #fff;
        }
        .sign::after {
            content: "*";
            color: red;
        }
    }
</style>
