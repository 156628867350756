<template>
    <div class="timeTable">
        <div class="table_time" ref="table_box">
            <div style="display: inline-block;overflow: hidden;">
                <div class="row">
                    <div class="one_item item_every">时间</div>
                    <div v-for="val in timeList" :key="val" class="other_item item_every">
                        {{ val + "点" }}
                    </div>
                </div>
                <div class="row" :ref="one.min" v-for="(one, k) in rowList" :key="k">
                    <div class="one_item item_every">{{ one.min + "分" }}</div>
                    <div :ref="val + '_ref_' + one.min" :id="val + '_id_' + one.min" v-for="val in timeList" :key="val" class="other_item item_every">
                        <div class="time_shift" v-for="(item, kk) in one[val]" :key="kk" :style="item.isDrag ? Drag : ''" @click="clickItem(item)">
                            <div
                                style="display: inline-block;"
                                @mousedown="mousedown(item)"
                                @mouseover="mouseover(item)"
                                @mouseleave="mouseleave(item)"
                                :ref="item.shiftId || item.waybillId"
                            >
                                <time-shift :item="item" :isShowIcon="isShowIcon" slot="reference"></time-shift>
                            </div>
                            <div class="popver_box" v-if="(popverId == item.shiftId || popverId == item.waybillId) && operationShow">
                                <div class="popver" v-for="(two, kkk) in operationList" :key="kkk">
                                    <div ref="popver_btn" class="popver_btn" v-if="two.isShow ? two.isShow(item) : true" @click="two.fun && two.fun(item)">
                                        {{ two.name }}
                                    </div>
                                </div>
                            </div>
                            <div class="remark_box" v-if="(remarkId == item.shiftId || remarkId == item.waybillId) && item.remark && !popverId">
                                {{ item.remark }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex_box">
            <div class="row">
                <div class="one_item item_every">时间</div>
            </div>
            <div class="row" :style="'height:' + one.height" v-for="(one, k) in rowList" :key="k">
                <div class="one_item item_every">{{ one.min + "分" }}</div>
            </div>
        </div>
    </div>
</template>
<script>
    import timeShift from "@/components/pages/admin/common/timeTable/timeShift";
    // import {caseColorList} from '@/components/pages/admin/scheduling/dailyTimetable/addUpdateSub/initData.js'
    class DataHour {
        constructor(min) {
            this.min = min;
            this.height = "0px";
            for (let i = 0; i < 24; i++) {
                this[String(i)] = [];
            }
        }
    }
    export default {
        props: {
            list: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            operationList: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            canUpdate: {
                type: Boolean,
                default: true
            },
            isInit: {
                type: Boolean,
                default: true
            },
            isShowIcon: {
                type: Boolean,
                default: true
            }
        },
        watch: {
            list: {
                handler(val) {
                    this.showList = this.$util.deepCopy(val);
                },
                immediate: true,
                deep: true
            },
            showList: {
                handler(val) {
                    let list = this.setNameColor(val);
                    console.log(list);
                    this.popverId = "";
                    let newList = [new DataHour(0), new DataHour(10), new DataHour(20), new DataHour(30), new DataHour(40), new DataHour(50)];
                    list.forEach(one => {
                        if (!one.dTime) {
                            one.dTime = one.dtime;
                        }
                        if (!one.oDate) {
                            one.oDate = one.odate;
                        }
                        let hours = Number(one.dTime.split(":")[0]);
                        let min = Number(one.dTime.split(":")[1]);
                        let key = -1;
                        newList.forEach((item, k) => {
                            if (min > item.min || min == item.min) {
                                if (!newList[k + 1] || (newList[k + 1] && min < newList[k + 1].min)) {
                                    key = k;
                                }
                            }
                        });
                        if (key != -1) {
                            newList[key][hours].push(one);
                        }
                    });
                    this.rowList = this.$util.deepCopy(newList);
                    this.$nextTick(() => {
                        this.refsList = Object.keys(this.$refs)
                            .map(item => {
                                return this.$refs[item][0];
                            })
                            .filter(one => {
                                return one && one.id && one.id.includes("_id_");
                            });
                        this.refsList.forEach(item => {
                            let refItemPosition = this.setScreenPosition(item);
                            item.innerLeft = refItemPosition.innerLeft;
                            item.innerRight = refItemPosition.innerLeft + item.offsetWidth;
                            item.innerTop = refItemPosition.innerTop;
                            item.innerBotton = refItemPosition.innerTop + item.offsetHeight;
                        });
                        // console.log(this.refsList)
                        this.rowList.forEach(item => {
                            item.height = this.$refs[item.min][0].offsetHeight + "px";
                        });
                        if (this.isInit) {
                            list.sort((a, b) => {
                                return Number(a.dTime.replace(":", "")) - Number(b.dTime.replace(":", ""));
                            });
                            let oneTtem = list[0];
                            if (oneTtem && oneTtem.dTime) {
                                let h = Number(oneTtem.dTime.split(":")[0]);
                                this.$refs.table_box.scrollTo(h * 200, 0);
                            }
                        }
                    });
                },
                immediate: true,
                deep: true
            },
            popverId() {
                this.operationFun();
            }
        },
        components: {
            timeShift
        },
        data() {
            return {
                showList: [],
                timeList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                rowList: [new DataHour(0), new DataHour(10), new DataHour(20), new DataHour(30), new DataHour(40), new DataHour(50)],
                dragItem: null,
                mouseItem: null,
                refsList: [],

                Drag: {
                    position: "relative",
                    left: "0px",
                    top: "0px",
                    zIndex: 100
                },

                refsItem: null,
                refsItemLeft: 0,
                refsItemTop: 0,
                popverId: "",
                remarkId: "",
                draging: false,

                operationShow: true
            };
        },
        created() {
            document.addEventListener("click", e => {
                if (e.path?.every(one => one._prevClass != "time_shift")) {
                    this.popverId = "";
                }
            });
            document.addEventListener("mousedown", e => {
                let obj = this.getMousePosition(e);
                if (this.dragItem) {
                    this.mouseItem = obj;
                }
            });
            document.addEventListener("mousemove", e => {
                if (this.dragItem) {
                    if (this.mouseItem) {
                        let obj = this.getMousePosition(e);
                        if (obj.pk == -1) {
                            return false;
                        }
                        // console.log(obj)
                        // console.log(this.mouseItem)

                        this.refsItemLeft = obj.totalLeft - this.mouseItem.totalLeft;
                        this.refsItemTop = obj.totalTop - this.mouseItem.totalTop;
                        if (this.refsItemLeft == 0 && this.refsItemTop == 0) {
                            return false;
                        }
                        this.popverId = "";
                        this.Drag = {
                            position: "relative",
                            zIndex: 100,
                            left: obj.totalLeft - this.mouseItem.totalLeft + "px",
                            top: obj.totalTop - this.mouseItem.totalTop + "px"
                        };
                        //时间变化
                        let id = "";
                        let y = this.refsItem.innerTop + this.refsItemTop;
                        let x = this.refsItem.innerLeft + this.refsItemLeft;
                        let top = 0;
                        this.refsList.forEach(item => {
                            if (item.innerTop < y && y < item.innerBotton && item.innerLeft < x && x < item.innerRight) {
                                id = item.id;
                                top = y - item.innerTop;
                            }
                        });
                        if (id && id.includes("_id_")) {
                            let refId = id.replace("_id_", "_ref_");
                            let hm = this.$refs[refId][0].offsetHeight / 10;

                            let list = id.split("_id_");
                            let addm = Math.round(top / hm);
                            list[1] = String(Number(list[1]) + addm);
                            list.forEach((item, k) => {
                                if (item.length == 1) {
                                    list[k] = "0" + item;
                                }
                            });
                            if (this.dragItem.dTime.split(":").length == 2) {
                                this.dragItem.dTime = list.join(":");
                            } else {
                                this.dragItem.dTime = list.concat("00").join(":");
                            }
                        }
                    }
                }
            });
            document.addEventListener("mouseup", () => {
                if (this.dragItem && (this.Drag.left != "0px" || this.Drag.top != "0px")) {
                    this.draging = true;
                    this.popverId = "";
                    let y = this.refsItem.innerTop + this.refsItemTop;
                    let x = this.refsItem.innerLeft + this.refsItemLeft;

                    let id = "";
                    this.refsList.forEach(item => {
                        if (item.innerTop < y && y < item.innerBotton && item.innerLeft < x && x < item.innerRight) {
                            id = item.id;
                        }
                    });
                    if (id && id.includes("_id_")) {
                        let key = -1;
                        this.showList.forEach((item, k) => {
                            if ((item.shiftId && item.shiftId == this.dragItem.shiftId) || (item.waybillId && item.waybillId == this.dragItem.waybillId)) {
                                key = k;
                            }
                        });
                        console.log(key);
                        let newTime = this.dragItem.dTime.split(":");
                        if (newTime[1] == "60") {
                            newTime[1] = "00";
                            newTime[0] = String(Number(newTime[0]) + 1);
                        }
                        newTime.forEach((item, k) => {
                            if (item.length == 1) {
                                newTime[k] = "0" + item;
                            }
                        });
                        let newdragItem = {
                            ...this.dragItem,
                            isDrag: false,
                            dTime: newTime.join(":")
                        };
                        this.$emit("dragItem", newdragItem);
                        console.log(newdragItem);
                        this.showList.splice(key, 1, newdragItem);
                    } else {
                        this.showList = this.$util.deepCopy(this.showList);
                    }
                }
                this.Drag = {
                    position: "relative",
                    left: "0px",
                    top: "0px",
                    zIndex: 100
                };
                if (this.dragItem) {
                    this.dragItem.isDrag = false;
                }
                this.dragItem = null;
                this.mouseItem = null;
                this.refsItem = null;
                this.refsItemLeft = 0;
                this.refsItemTop = 0;
            });
        },
        methods: {
            setNameColor(list) {
                let caseList = [];
                let prop = "";
                if (list.every(item => item.schemeNa)) {
                    prop = "schemeNa";
                } else {
                    prop = "schemeId";
                }
                list.forEach(one => {
                    if (!caseList.some(item => item.prop == one[prop])) {
                        caseList.push({
                            prop: one[prop],
                            children: [one]
                        });
                    } else {
                        caseList.filter(item => item.prop == one[prop])[0].children.push(one);
                    }
                });
                let newList = [];
                caseList.forEach(item => {
                    item.children.sort((a, b) => {
                        let atime = Number(a.dTime.split(":").join(""));
                        let btime = Number(b.dTime.split(":").join(""));
                        return atime - btime;
                    });
                    let itemList = item.children.map((item, kk) => {
                        let newObj = {
                            ...item,
                            isDrag: false,
                            shiteNa: "班次" + (kk + 1)
                        };
                        if (prop == "schemeId") {
                            // newObj.schemeC = caseColorList[k]
                            switch (item.eState) {
                                case "3":
                                    newObj.schemeC = "#f1c40f";
                                    break;
                                case "2":
                                    newObj.schemeC = "#1abc9c";
                                    break;
                                case "1":
                                    newObj.schemeC = "#9b59b6";
                                    break;
                                case "0":
                                    newObj.schemeC = "#d35400";
                                    break;
                                case "4":
                                    newObj.schemeC = "#c0392b";
                                    break;
                                default:
                                    newObj.schemeC = "#61a5ff";
                                    break;
                            }
                        }
                        return newObj;
                    });
                    newList = newList.concat(itemList);
                });
                return newList;
            },
            operationFun() {
                this.$nextTick(() => {
                    console.log(this.$refs.popver_btn);
                    this.operationShow = this.canUpdate && this.$refs.popver_btn ? true : false;
                });
            },
            getMousePosition(e) {
                let pk = -1;
                e.path?.forEach((item, k) => {
                    if (item._prevClass == "table_time") {
                        pk = k;
                    }
                });
                let pRefs = [];
                if (pk != -1) {
                    pRefs = e.path.filter((item, k) => k > pk || k == pk);
                }
                let scrollTopTotal = 0;
                let scrollLeftTotal = 0;
                pRefs.forEach(one => {
                    if (one.scrollTop) {
                        scrollTopTotal += one.scrollTop;
                    }
                    if (one.scrollLeft) {
                        scrollLeftTotal += one.scrollLeft;
                    }
                });
                return {
                    totalTop: scrollTopTotal + e.clientY,
                    totalLeft: scrollLeftTotal + e.clientX,
                    pk
                };
            },
            setScreenPosition(ref) {
                let innerLeft = ref.offsetLeft;
                let innerTop = ref.offsetTop;
                if (ref.offsetParent) {
                    let pitem = this.setScreenPosition(ref.offsetParent);
                    innerLeft += pitem.innerLeft;
                    innerTop += pitem.innerTop;
                }
                return {
                    innerLeft,
                    innerTop
                };
            },
            clickItem(item) {
                if (!this.draging) {
                    this.popverId = item.shiftId || item.waybillId;
                }
                this.draging = false;
                this.$emit("clickItem", item);
            },
            mousedown(item) {
                if (this.canUpdate) {
                    this.dragItem = item;
                    this.dragItem.isDrag = true;
                    let refsItem = this.$refs[item.shiftId || item.waybillId][0];
                    let refItemPosition = this.setScreenPosition(refsItem);
                    refsItem.innerLeft = refItemPosition.innerLeft;
                    refsItem.innerRight = refItemPosition.innerLeft + refsItem.offsetWidth;
                    refsItem.innerTop = refItemPosition.innerTop;
                    refsItem.innerBotton = refItemPosition.innerTop + refsItem.offsetHeight;
                    this.refsItem = refsItem;
                }
            },
            mouseover(item) {
                this.remarkId = item.shiftId || item.waybillId;
            },
            mouseleave() {
                this.remarkId = "";
            }
        }
    };
</script>
<style lang="scss" scoped="timeTable">
    .timeTable {
        font-size: 12px;
        border: 1px solid #ebeef5;
        position: relative;

        .table_time {
            overflow: auto;
        }

        .row {
            display: inline-flex;
            flex-wrap: nowrap;

            div {
                display: inline-block;
            }

            .one_item {
                width: 50px;
            }

            .other_item {
                width: 200px;
            }

            .item_every {
                padding: 5px;

                .time_shift {
                    @extend .hand;
                    display: inline-block;
                    padding: 3px;
                    position: relative;
                }

                + .item_every {
                    border-left: 1px solid #ebeef5;
                }
            }

            + .row {
                min-height: 100px;
                border-top: 1px solid #ebeef5;
            }
        }

        .flex_box {
            position: absolute;
            background-color: #fff;
            left: 0;
            top: 0;

            .row {
                display: flex;
            }
        }

        .popver_box {
            z-index: 100;
            position: absolute;
            right: -75px;
            top: -10px;
            width: 70px;
            border: 1px solid #ebeef5;
            background-color: #fff;
            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
            div {
                display: block;
            }
            .popver {
                .popver_btn {
                    padding: 2px 0;
                    text-align: center;
                }
                + .popver {
                    .popver_btn {
                        border-top: 1px solid #ebeef5;
                    }
                }
            }
        }
        .remark_box {
            z-index: 90;
            position: absolute;
            border: 1px solid #ebeef5;
            background-color: #fff;
            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
            width: 100px;
            min-height: 40px;
            right: -70px;
            top: -10px;
        }
    }
</style>
